import * as Sentry from '@sentry/angular';

export function handleIntegrations(): void {
  initSentry();
  initHotjar();
  initGoogleAnalytics();
}

function initSentry(): void {
  Sentry.init({
    dsn: "https://60236da900aa8e3fd1eba789807da88e@o4505992663793664.ingest.sentry.io/4505993237692416",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({levels: ['error']})
    ],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "https://interactive-map-ai.com/"],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

function initHotjar(): void {
  (function(h,o,t,j,a,r){
    //@ts-ignore
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    //@ts-ignore
    h._hjSettings={hjid:3729323,hjsv:6};
    //@ts-ignore
    a=o.getElementsByTagName('head')[0];
    //@ts-ignore
    r=o.createElement('script');r.async=1;
    //@ts-ignore
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    //@ts-ignore
    a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}

function initGoogleAnalytics(): void {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  function gtag() {
  // @ts-ignore
    window.dataLayer.push(arguments);
  }

  // Load the Google Analytics script
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-ETVYPJ4VGL';
  document.head.appendChild(script);

  script.onload = () => {
    // Initialize Google Analytics after the script is loaded
    // @ts-ignore
    gtag('js', new Date());
    // @ts-ignore
    gtag('config', 'G-ETVYPJ4VGL');
  };
}

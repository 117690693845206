import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  retryWhen,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { ACCESS_TOKEN, AuthenticationService } from './authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../shared/services/modal.service';
import { signIn } from './map-redirect-modal/map-redirect-modal-text';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  private refreshTokenRetryCount = 0;
  private maxRefreshTokenRetries = 6;

  private isToastShown = false;

  constructor(
    private authService: AuthenticationService,
    private toast: ToastrService,
    private modalService: ModalService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Skip 401 handling for refresh token requests to avoid infinite loops
    const isRefreshTokenRequest = req.url.includes('/token/refresh');

    if (this.authService.hasAccessToken()) {
      const accessToken = this.authService.getTokens()[ACCESS_TOKEN];

      const authRequest = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + accessToken),
      });

      return next.handle(authRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          if (
            error.status === 401 &&
            this.authService.hasAccessToken() &&
            !isRefreshTokenRequest
          ) {
            return this.handle401Error(authRequest, next);
          } else if (error.status === 401 && isRefreshTokenRequest) {
            // Handle refresh token failure directly and increment counter
            this.refreshTokenRetryCount++;

            return throwError(() => error);
          } else if (error.status === 429 && !this.isToastShown) {
            this.toast
              .warning('Too many request, please try later')
              .onHidden.subscribe(() => (this.isToastShown = false));
            this.isToastShown = true;
          }

          return throwError(() => error);
        })
      );
    } else {
      return next.handle(req);
    }
  }

  private handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);

      return this.refreshTokenWithLimit().pipe(
        switchMap((accessToken) => {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(accessToken);
          return next.handle(this.addToken(req, accessToken)); // Retry the original request
        }),
        catchError((err) => {
          this.refreshTokenInProgress = false;
          // this.toast.info('Please log in again', 'Sign in')
          this.modalService.openModal(signIn);
          this.authService.setTokens('', ''); // Manually nullify tokens
          // this.authService.logout(); // Log out on refresh failure
          return throwError(() => err);
        })
      );
    } else {
      // Queue the request until the token refresh completes
      return this.refreshTokenSubject.pipe(
        filter((token): token is string => token !== null),
        take(1), // Only take the first valid token emitted
        switchMap((accessToken) => {
          // Retry the original request with the new token
          return next.handle(this.addToken(req, accessToken));
        })
      );
    }
  }

  private refreshTokenWithLimit(): Observable<string> {
    return this.authService.refreshToken().pipe(
      map((response) => response.object.tokens.access),
      retryWhen((errors) =>
        errors.pipe(
          tap((err) => {
            this.refreshTokenRetryCount++;
          }),
          delay(1000),
          mergeMap((err) => {
            if (this.refreshTokenRetryCount < this.maxRefreshTokenRetries) {
              // Continue retrying
              return of(err);
            } else {
              // Exceeded max retries, throw an error
              console.error(
                'Max refresh token retries reached:',
                this.refreshTokenRetryCount
              );
              return throwError(
                () => new Error('Max refresh attempts reached')
              );
            }
          })
        )
      ),
      tap(() => {
        this.refreshTokenRetryCount = 0; // Reset retry count on successful refresh
      }),
      catchError((err) => {
        console.error('Error in refreshTokenWithLimit:', err);
        return throwError(() => err);
      })
    );
  }

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    // Clone the request and add the new token
    return req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + token),
    });
  }
}

import {
  APP_INITIALIZER,
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';
import { handleIntegrations } from './app/shared/util/integrations';
import { AppComponent } from './app/app.component';
import { ToastrModule } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { appRoutingProviders } from './app/app-routing.module';
import { bootstrapApplication } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { ErrorInterceptor } from './app/shared/api/error.interceptor';
import { AuthInterceptor } from './app/user/auth-interceptor';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { Router } from '@angular/router';

if (environment.production) {
  enableProdMode();
  handleIntegrations();
}
//@ts-ignore
bootstrapApplication(AppComponent, {
  providers: [
    appRoutingProviders,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(ToastrModule.forRoot()),
    DatePipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
}).catch((err) => console.error(err));

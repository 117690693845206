import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  private isToastShown = false;

  constructor(private toast: ToastrService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen((errors) =>
        errors.pipe(
          mergeMap((error, index) => {
            if (error.status === 502 && index < 3) {
              return timer(2000);
            }
            return throwError(() => error);
          })
        )
      ),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 502 && !this.isToastShown) {
          this.toast
            .error('Please try again later.', 'Something went wrong')
            .onHidden.subscribe(() =>
              setTimeout(() => (this.isToastShown = false), 15_000)
            );
          this.isToastShown = true;
        }
        return throwError(() => error);
      })
    );
  }
}

import {
  PreloadAllModules,
  provideRouter,
  Routes,
  withPreloading,
} from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { AdminGuard } from './shared/guards/admin.guard';
import { NavigationBlockManualGuard } from './shared/guards/navigation-block-manual.guard';

export const appRoutes: Routes = [
  {
    path: 'info',
    title: 'Info - Map AI',
    loadChildren: () =>
      import('./support/info.module').then((m) => m.InfoModule),
    canActivate: [NavigationBlockManualGuard],
  },
  {
    path: 'admin',
    title: 'Admin - Map AI',
    loadComponent: () =>
      import('./admin/admin.component').then((c) => c.AdminComponent),
    canLoad: [AdminGuard],
    canActivate: [AdminGuard],
  },
  {
    path: 'comparative-location-tool',
    title: 'Comparative Location Tool - Map AI',
    loadComponent: () =>
      import(
        './comparative-location-tool/comparative-location-tool.component'
      ).then((c) => c.ComparativeLocationToolComponent),
    canLoad: [AuthGuard],
    canActivate: [NavigationBlockManualGuard, AuthGuard],
  },
  {
    path: 'reports',
    title: 'Reports - Map AI',
    loadChildren: () =>
      import('./reports/reports.routes').then((r) => r.reportsRoutes),
    canActivate: [NavigationBlockManualGuard],
  },
  {
    path: 'user',
    title: 'User - Map AI',
    loadChildren: () => import('./user/user.routes').then((r) => r.userRoutes),
    canActivate: [NavigationBlockManualGuard, AuthGuard],
  },
  {
    path: 'sign-in',
    loadComponent: () =>
      import('./user/sign-in/sign-in.component').then((c) => c.SignInComponent),
    canActivate: [NavigationBlockManualGuard],
  },
  {
    path: 'password-recovery',
    loadComponent: () =>
      import('./user/forgot-password-page/forgot-password-page.component').then(
        (c) => c.ForgotPasswordPageComponent
      ),
  },
  {
    path: 'user/password/reset',
    loadComponent: () =>
      import(
        './user/forgot-password-page/reset-password/reset-password.component'
      ).then((c) => c.ResetPasswordComponent),
  },
  {
    path: 'sign-up',
    loadComponent: () =>
      import('./user/sign-up/sign-up.component').then((c) => c.SignUpComponent),
    canActivate: [NavigationBlockManualGuard],
  },
  {
    path: 'sign-up/individual',
    loadComponent: () =>
      import(
        './user/sign-up/register-individual/register-individual.component'
      ).then((c) => c.RegisterIndividualComponent),
  },
  {
    path: 'sign-up/success',
    loadComponent: () =>
      import(
        './user/sign-up/register-individual/registration-success-window/registration-success-window.component'
      ).then((c) => c.RegistrationSuccessWindowComponent),
  },
  {
    path: '',
    title: 'Map AI',
    loadComponent: () =>
      import('./map/map.component').then((c) => c.MapComponent),
    canActivate: [NavigationBlockManualGuard],
  },
];

export const appRoutingProviders = provideRouter(
  appRoutes,
  withPreloading(PreloadAllModules)
);
